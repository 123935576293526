export default [
  {
    path: '/orders',
    name: 'orders-index',
    component: () => import('@/views/orders/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.orders',
      breadcrumb: [
        {
          text: 'administration.navigation.orders',
          active: true,
        },
      ],
      resource: 'orders',
      action: 'index',
    },
  },
  {
    path: '/orders-stat',
    name: 'orders-stat',
    component: () => import('@/views/orders/Stat.vue'),
    meta: {
      pageTitle: 'administration.navigation.orders_stat',
      breadcrumb: [
        {
          text: 'administration.navigation.orders_stat',
          active: true,
        },
      ],
      resource: 'orders',
      action: 'index',
    },
  },
  {
    path: '/orders/show/:id',
    name: 'orders-show',
    component: () => import('@/views/orders/Show.vue'),
    meta: {
      pageTitle: 'administration.navigation.orders',
      breadcrumb: [
        {
          text: 'administration.navigation.orders',
          to: { name: 'orders-index' },
        },
        {
          text: 'general.view',
          active: true,
        },
      ],
      resource: 'orders',
      action: 'show',
    },
  },
]
